import * as React from "react";
import * as ReactDOM from "react-dom";
import { FetchData } from "./components/FetchData";

function render() {
    let element = document.getElementById("divRecentlyViewedReact");
    if (element == null) {
        setTimeout(render, 100);
        return;
    }
    ReactDOM.render(
        <FetchData />,
        element
    );
}
render();